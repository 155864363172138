import React from "react";
import Amazon from './amazon-.png'
import './index.css';

const Header = () => {
  return (
    <header>
      <div className="container">
      <img
          src={Amazon}
          alt="logo of Amazon"
          className="logo"
        />
        <h1 className="name"> Process Assistant </h1>
      </div>
    </header>
  );
};

export default Header;
