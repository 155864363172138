import Checklist from "./Checklist";
import Links from "./Links";
import Visualizations from "./Visualizations";
import "./App.css";
const App = () => {
  return (
    <div>
      <div className="container">
        <div className="column">
          <Checklist />
        </div>
        <div className="column">
          <Visualizations />
        </div>
      </div>
      <Links />
    </div>
  );
};

export default App;
