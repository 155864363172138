import "./App.css";

const Links = () => {
    const items = [
        { name: 'FCLM-portal', url: 'https://fclm-portal.amazon.com' },
        { name: 'Inbound Flow Control', url: 'https://inbound-flow-control.amazon.com' },
        { name: 'Flow Sortation', url: 'https://flow-sortation-na.amazon.com' },
        { name: 'Fan Iad', url:'https://fans-iad.amazon.com/'}, 
        { name: 'Fclerning', url:'https://fclearning.amazon.com/'},
        { name: 'Inbound Flow', url:'https://inbound-flow-control.amazon.com/#/RMN3/indirect'},
        
      ];
  
      // Render the list using map
      const listItems = items.map((item, index) => (
        <li key={index}>
          <a href={item.url}>{item.name}</a>
        </li>
      ));
  
      return (
        
        <div>
          <ul>{listItems}</ul>
        </div>
      );
};

export default Links;
