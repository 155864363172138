import React, { useState } from "react";
import Header from "./Header";
import "./App.css";

const Checklist = () => {
  const [items, setItems] = useState([
    { id: 1, text: "Get battery for radio", checked: false, link: null },
    {
      id: 2,
      text: "Begin your shift by conducting a walkthrough to ensure that the assigned AAs (referring to associates) have the necessary tools for their tasks and that the workspace is tidy and organized.",
      checked: false,
      link: null,
    },
    {
      id: 3,
      text: "At the end of your shift, walk through the area to ensure that the totes are replenished with supplies for the success of the other shift.",
      checked: false,
      link: null,
    },
    {
      id: 4,
      text: "Do 5 apollo audit in Quality",
      checked: false,
      link: "https://apollo-audit.corp.amazon.com/audits/new?audit_type_id=11029",
    },
    {
      id: 5,
      text: "Do 4 simbas",
      checked: false,
      link: "https://durable.corp.amazon.com/RMN3/simba/audits/questions_list?fc_type=IXD%2B",
    },
    {
      id: 6,
      text: "Do 1 InstaClean",
      checked: false,
      link: "https://durable.corp.amazon.com/RMN3/instaclean",
    },
    {
      id: 7,
      text: "Working Well Huddles Every Month",
      checked: false,
      link: "https://scorecard.workingwell.whs.amazon.dev/huddleroster?manager_id=202892764&module=99&site=RMN3",
    },
    {
      id: 8,
      text: "Indirect work code",
      checked: false,
      link: "https://fcmenu-iad-regionalized.corp.amazon.com/RMN3/laborTrackingKiosk",
    },
    {
      id: 9,
      text: "Verify the Time of Task (TOT) for each employee.",
      checked: false,
      link: "https://fclm-portal.amazon.com/reports/timeOnTask?&warehouseId=RMN3",
    },
  ]);

  const handleCheckChange = (itemId) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId ? { ...item, checked: !item.checked } : item
      )
    );
  };
  return (
    <div className="container1">
      <Header />

      <ul>
        {items.map((item) => (
          <li key={item.id}>
            <label>
              <input
                type="checkbox"
                checked={item.checked}
                onChange={() => handleCheckChange(item.id)}
              />
              {item.link ? (
                <a href={item.link}>{item.text}</a>
              ) : (
                <span>{item.text}</span>
              )}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Checklist;
